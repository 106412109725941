/* polaris version 6 css */
.polaris_css {
  background-color: rgb(246, 246, 247);
  color: rgb(32, 34, 35);
  --p-background: rgba(246, 246, 247, 1);
  --p-background-hovered: rgba(241, 242, 243, 1);
  --p-background-pressed: rgba(237, 238, 239, 1);
  --p-background-selected: rgba(237, 238, 239, 1);
  --p-surface: rgba(255, 255, 255, 1);
  --p-surface-neutral: rgba(228, 229, 231, 1);
  --p-surface-neutral-hovered: rgba(219, 221, 223, 1);
  --p-surface-neutral-pressed: rgba(201, 204, 208, 1);
  --p-surface-neutral-disabled: rgba(241, 242, 243, 1);
  --p-surface-neutral-subdued: rgba(246, 246, 247, 1);
  --p-surface-subdued: rgba(250, 251, 251, 1);
  --p-surface-disabled: rgba(250, 251, 251, 1);
  --p-surface-hovered: rgba(246, 246, 247, 1);
  --p-surface-pressed: rgba(241, 242, 243, 1);
  --p-surface-depressed: rgba(237, 238, 239, 1);
  --p-backdrop: rgba(0, 0, 0, 0.5);
  --p-overlay: rgba(255, 255, 255, 0.5);
  --p-shadow-from-dim-light: rgba(0, 0, 0, 0.2);
  --p-shadow-from-ambient-light: rgba(23, 24, 24, 0.05);
  --p-shadow-from-direct-light: rgba(0, 0, 0, 0.15);
  --p-hint-from-direct-light: rgba(0, 0, 0, 0.15);
  --p-on-surface-background: rgba(241, 242, 243, 1);
  --p-border: rgba(140, 145, 150, 1);
  --p-border-neutral-subdued: rgba(186, 191, 195, 1);
  --p-border-hovered: rgba(153, 158, 164, 1);
  --p-border-disabled: rgba(210, 213, 216, 1);
  --p-border-subdued: rgba(201, 204, 207, 1);
  --p-border-depressed: rgba(87, 89, 89, 1);
  --p-border-shadow: rgba(174, 180, 185, 1);
  --p-border-shadow-subdued: rgba(186, 191, 196, 1);
  --p-divider: rgba(225, 227, 229, 1);
  --p-icon: rgba(92, 95, 98, 1);
  --p-icon-hovered: rgba(26, 28, 29, 1);
  --p-icon-pressed: rgba(68, 71, 74, 1);
  --p-icon-disabled: rgba(186, 190, 195, 1);
  --p-icon-subdued: rgba(140, 145, 150, 1);
  --p-text: rgba(32, 34, 35, 1);
  --p-text-disabled: rgba(140, 145, 150, 1);
  --p-text-subdued: rgba(109, 113, 117, 1);
  --p-interactive: rgba(44, 110, 203, 1);
  --p-interactive-disabled: rgba(189, 193, 204, 1);
  --p-interactive-hovered: rgba(31, 81, 153, 1);
  --p-interactive-pressed: rgba(16, 50, 98, 1);
  --p-focused: rgba(69, 143, 255, 1);
  --p-surface-selected: rgba(242, 247, 254, 1);
  --p-surface-selected-hovered: rgba(237, 244, 254, 1);
  --p-surface-selected-pressed: rgba(229, 239, 253, 1);
  --p-icon-on-interactive: rgba(255, 255, 255, 1);
  --p-text-on-interactive: rgba(255, 255, 255, 1);
  --p-action-secondary: rgba(255, 255, 255, 1);
  --p-action-secondary-disabled: rgba(255, 255, 255, 1);
  --p-action-secondary-hovered: rgba(246, 246, 247, 1);
  --p-action-secondary-pressed: rgba(241, 242, 243, 1);
  --p-action-secondary-depressed: rgba(109, 113, 117, 1);
  --p-action-primary: rgba(0, 128, 96, 1);
  --p-action-primary-disabled: rgba(241, 241, 241, 1);
  --p-action-primary-hovered: rgba(0, 110, 82, 1);
  --p-action-primary-pressed: rgba(0, 94, 70, 1);
  --p-action-primary-depressed: rgba(0, 61, 44, 1);
  --p-icon-on-primary: rgba(255, 255, 255, 1);
  --p-text-on-primary: rgba(255, 255, 255, 1);
  --p-text-primary: rgba(0, 123, 92, 1);
  --p-text-primary-hovered: rgba(0, 108, 80, 1);
  --p-text-primary-pressed: rgba(0, 92, 68, 1);
  --p-surface-primary-selected: rgba(241, 248, 245, 1);
  --p-surface-primary-selected-hovered: rgba(179, 208, 195, 1);
  --p-surface-primary-selected-pressed: rgba(162, 188, 176, 1);
  --p-border-critical: rgba(253, 87, 73, 1);
  --p-border-critical-subdued: rgba(224, 179, 178, 1);
  --p-border-critical-disabled: rgba(255, 167, 163, 1);
  --p-icon-critical: rgba(215, 44, 13, 1);
  --p-surface-critical: rgba(254, 211, 209, 1);
  --p-surface-critical-subdued: rgba(255, 244, 244, 1);
  --p-surface-critical-subdued-hovered: rgba(255, 240, 240, 1);
  --p-surface-critical-subdued-pressed: rgba(255, 233, 232, 1);
  --p-surface-critical-subdued-depressed: rgba(254, 188, 185, 1);
  --p-text-critical: rgba(215, 44, 13, 1);
  --p-action-critical: rgba(216, 44, 13, 1);
  --p-action-critical-disabled: rgba(241, 241, 241, 1);
  --p-action-critical-hovered: rgba(188, 34, 0, 1);
  --p-action-critical-pressed: rgba(162, 27, 0, 1);
  --p-action-critical-depressed: rgba(108, 15, 0, 1);
  --p-icon-on-critical: rgba(255, 255, 255, 1);
  --p-text-on-critical: rgba(255, 255, 255, 1);
  --p-interactive-critical: rgba(216, 44, 13, 1);
  --p-interactive-critical-disabled: rgba(253, 147, 141, 1);
  --p-interactive-critical-hovered: rgba(205, 41, 12, 1);
  --p-interactive-critical-pressed: rgba(103, 15, 3, 1);
  --p-border-warning: rgba(185, 137, 0, 1);
  --p-border-warning-subdued: rgba(225, 184, 120, 1);
  --p-icon-warning: rgba(185, 137, 0, 1);
  --p-surface-warning: rgba(255, 215, 157, 1);
  --p-surface-warning-subdued: rgba(255, 245, 234, 1);
  --p-surface-warning-subdued-hovered: rgba(255, 242, 226, 1);
  --p-surface-warning-subdued-pressed: rgba(255, 235, 211, 1);
  --p-text-warning: rgba(145, 106, 0, 1);
  --p-border-highlight: rgba(68, 157, 167, 1);
  --p-border-highlight-subdued: rgba(152, 198, 205, 1);
  --p-icon-highlight: rgba(0, 160, 172, 1);
  --p-surface-highlight: rgba(164, 232, 242, 1);
  --p-surface-highlight-subdued: rgba(235, 249, 252, 1);
  --p-surface-highlight-subdued-hovered: rgba(228, 247, 250, 1);
  --p-surface-highlight-subdued-pressed: rgba(213, 243, 248, 1);
  --p-text-highlight: rgba(52, 124, 132, 1);
  --p-border-success: rgba(0, 164, 124, 1);
  --p-border-success-subdued: rgba(149, 201, 180, 1);
  --p-icon-success: rgba(0, 127, 95, 1);
  --p-surface-success: rgba(174, 233, 209, 1);
  --p-surface-success-subdued: rgba(241, 248, 245, 1);
  --p-surface-success-subdued-hovered: rgba(236, 246, 241, 1);
  --p-surface-success-subdued-pressed: rgba(226, 241, 234, 1);
  --p-text-success: rgba(0, 128, 96, 1);
  --p-decorative-one-icon: rgba(126, 87, 0, 1);
  --p-decorative-one-surface: rgba(255, 201, 107, 1);
  --p-decorative-one-text: rgba(61, 40, 0, 1);
  --p-decorative-two-icon: rgba(175, 41, 78, 1);
  --p-decorative-two-surface: rgba(255, 196, 176, 1);
  --p-decorative-two-text: rgba(73, 11, 28, 1);
  --p-decorative-three-icon: rgba(0, 109, 65, 1);
  --p-decorative-three-surface: rgba(146, 230, 181, 1);
  --p-decorative-three-text: rgba(0, 47, 25, 1);
  --p-decorative-four-icon: rgba(0, 106, 104, 1);
  --p-decorative-four-surface: rgba(145, 224, 214, 1);
  --p-decorative-four-text: rgba(0, 45, 45, 1);
  --p-decorative-five-icon: rgba(174, 43, 76, 1);
  --p-decorative-five-surface: rgba(253, 201, 208, 1);
  --p-decorative-five-text: rgba(79, 14, 31, 1);
  --p-border-radius-base: 0.4rem;
  --p-border-radius-wide: 0.8rem;
  --p-border-radius-full: 50%;
  --p-card-shadow: 0px 0px 5px var(--p-shadow-from-ambient-light),
    0px 1px 2px var(--p-shadow-from-direct-light);
  --p-popover-shadow: -1px 0px 20px var(--p-shadow-from-ambient-light),
    0px 1px 5px var(--p-shadow-from-direct-light);
  --p-modal-shadow: 0px 26px 80px var(--p-shadow-from-dim-light),
    0px 0px 1px var(--p-shadow-from-dim-light);
  --p-top-bar-shadow: 0 2px 2px -1px var(--p-shadow-from-direct-light);
  --p-button-drop-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  --p-button-inner-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.2);
  --p-button-pressed-inner-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.15);
  --p-override-none: none;
  --p-override-transparent: transparent;
  --p-override-one: 1;
  --p-override-visible: visible;
  --p-override-zero: 0;
  --p-override-loading-z-index: 514;
  --p-button-font-weight: 500;
  --p-non-null-content: "";
  --p-choice-size: 2rem;
  --p-icon-size: 1rem;
  --p-choice-margin: 0.1rem;
  --p-control-border-width: 0.2rem;
  --p-banner-border-default: inset 0 0.1rem 0 0 var(--p-border-neutral-subdued),
    inset 0 0 0 0.1rem var(--p-border-neutral-subdued);
  --p-banner-border-success: inset 0 0.1rem 0 0 var(--p-border-success-subdued),
    inset 0 0 0 0.1rem var(--p-border-success-subdued);
  --p-banner-border-highlight: inset 0 0.1rem 0 0
      var(--p-border-highlight-subdued),
    inset 0 0 0 0.1rem var(--p-border-highlight-subdued);
  --p-banner-border-warning: inset 0 0.1rem 0 0 var(--p-border-warning-subdued),
    inset 0 0 0 0.1rem var(--p-border-warning-subdued);
  --p-banner-border-critical: inset 0 0.1rem 0 0
      var(--p-border-critical-subdued),
    inset 0 0 0 0.1rem var(--p-border-critical-subdued);
  --p-badge-mix-blend-mode: luminosity;
  --p-thin-border-subdued: 0.1rem solid var(--p-border-subdued);
  --p-text-field-spinner-offset: 0.2rem;
  --p-text-field-focus-ring-offset: -0.4rem;
  --p-text-field-focus-ring-border-radius: 0.7rem;
  --p-button-group-item-spacing: -0.1rem;
  --p-duration-1-0-0: 100ms;
  --p-duration-1-5-0: 150ms;
  --p-ease-in: cubic-bezier(0.5, 0.1, 1, 1);
  --p-ease: cubic-bezier(0.4, 0.22, 0.28, 1);
  --p-range-slider-thumb-size-base: 1.6rem;
  --p-range-slider-thumb-size-active: 2.4rem;
  --p-range-slider-thumb-scale: 1.5;
  --p-badge-font-weight: 400;
  --p-frame-offset: 0px;
}
.Polaris-Frame-Toast {
  background: rgba(32, 33, 35, 1);
  color: rgba(227, 229, 231, 1);
}
/* polaris version 6 css */
.minwidth {
  min-width: 300px;
}
.content_section {
  margin: 0 20px;
  display: flex;
  align-items: baseline;
  position: relative;
  z-index: 1;
}
.funnel_app {
  border: 1px solid #c4cdd5;
  border-radius: 5px;
  background-color: #f2f4f7;
  position: relative;
  top: 31px;
  margin-bottom: 30px;
}
.funnel_app h3 {
  background-color: #fff;
  color: #8c9196;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
  border-bottom: 1px solid #c4cdd5;
  padding: 5px 0;
  font-size: 13px;
  font-weight: 700;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0.7rem;
  border-top-left-radius: 0.7rem;
}
.funnel_section {
  padding: 0 10px 10px;
}
.funnel_section h5 {
  padding: 9px 0px;
  text-align: center;
  color: #6d7175;
  margin: 0;
  font-size: 14px;
}
.funnel_wrapper {
  background-color: #fff;
  padding: 5px 10px;
  border-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.block_section .css-2b097c-container {
  width: 150px;
}
.block_section {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  flex-wrap: wrap;
}
.block_section > div {
  margin-right: 10px;
}
.block_section > div:last-child {
  margin-right: 0;
}
.Polaris-Labelled--hidden {
  margin-top: 20px;
  color: hsl(0, 0%, 20%);
}
#space_txt {
  margin-top: 20px;
  color: hsl(0, 0%, 20%);
  position: relative;
  font-family: -apple-system, blinkmacsystemfont, san francisco, segoe ui,
    roboto, helvetica neue, sans-serif !important;
  font-size: 14px;
}
.block_section > select {
  width: 100%;
  padding: 5px 0;
  border: 1px solid #bfb8b8;
  border-radius: 3px;
  cursor: pointer;
}
.block_section p {
  margin: 5px 0;
  font-weight: 400;
  color: #202223;
  line-height: 14px;
}
.funnel_wrapper a {
  padding: 5px 10px;
  display: inline-block;
  border: 1px solid grey;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 10px;
  background-color: #fffbfb;
  border-radius: 3px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #637381 transparent !important;
  border-width: 4px 4px 0 !important;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #637381 !important;
  border-width: 0 4px 4px !important;
}
.funnel_section .funnel_wrapper > button {
  padding: 5px 15px;
  background: linear-gradient(180deg, #fff 0%, #f9fafb 100%);
  border: 1px solid #c4cdd5;
  cursor: pointer;
  margin-left: 10px;
  background-color: #fffbfb;
  border-radius: 3px;
}
.funnel_section > button {
  padding: 5px 15px;
  border: 1px solid #c4cdd5;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 10px;
  background: linear-gradient(180deg, #fff 0%, #f9fafb 100%);
  border-radius: 3px;
}
.middle_section {
  position: relative;
  top: 45px;
  margin: 0 50px;
}
.middle_section span {
  color: #8c9196;

  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 2rem;
  font-size: 14px;
  line-height: 1.6rem;
  position: relative;
  width: max-content;
  top: 0;
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco", Roboto,
    "Segoe UI", "Helvetica Neue", sans-serif !important;
}
.middle_section_left {
  position: relative;
  top: 45px;
  margin: 0 50px 0 0;
}

.middle_section_left span {
  color: #8c9196;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 2rem;
  font-size: 14px;
  line-height: 1.6rem;
  position: relative;
  width: max-content;
  top: 0;
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco", Roboto,
    "Segoe UI", "Helvetica Neue", sans-serif !important;
}

.middle_section_left> :nth-child(1).blue_badge::after {
  content: "";
  position: absolute;
  right: -52px;
  border-top: 1px solid #c4cdd5;
  width: 52px;
  top: 14px;
}
.last_section {
  position: relative;
  width: 198px;
  top: 31px;
  z-index: -1;
}
.last_section h3 {
  word-break: break-all;
  background-color: #fff;
  color: #8c9196;
  font-size: 13px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #c4cdd5;
  padding: 5px 25px;
  vertical-align: bottom;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  width: 198px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.accordian_main {
  display: block !important;
}
.lastsection_content {
  background-color: #f9fafb;
}
.lastsection_content select {
  width: 100%;
  padding: 5px 0;
  border: 1px solid #bfb8b8;
  border-radius: 3px;
  cursor: pointer;
  margin: 5px 0;
  pointer-events: none;
}
.lastsection_content .css-yk16xz-control {
  cursor: pointer;
  border-radius: 0;
}
.lastsection_content .css-26l3qy-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dropdown_section {
  background-color: #fff;
  border: 1px solid #bfb8b8;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
}
.dropdown_section ul {
  list-style: none;
  padding-left: 0;
  margin: 0px;
}
.dropdown_section ul li:first-child {
  font-size: 20px;
  margin-bottom: 10px;
}
.dropdown_section ul li {
  cursor: pointer;
  padding: 5px 0;
  padding: 10px;
}
.dropdown_section ul li:hover {
  background-color: lightgrey;
}
.dropdown_section li span {
  font-weight: 600;
  font-size: 40px;
  line-height: 30px;
  vertical-align: text-bottom;
  margin-right: 10px;
  color: #3093e6;
}
.offer_section {
  background-color: #f1f4f7;
  padding: 8px;
  border: 1px solid #c4cdd5;
  position: relative;
  z-index: 1;
  border-radius: 0px 0px 7px 7px;
  border-top: 0;
}
.dynamic_upsell {
  position: relative;
}
.last_section::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 75px;
  width: 1px;
  background-color: #c4cdd5;
  margin: auto;
  z-index: -1;
}
.next_bottom_section .last_section::after {
  content: "";
  position: absolute;
  bottom: -70px;
  border-left: 1px solid #c4cdd5;
  height: 70px;
  left: 50%;
}
.down_wrapper {
  margin-bottom: 70px;
}
.righ_section_part {
  position: relative;
}
.up_wrapper .next_bottom_wrapper {
  position: absolute;
  top: 30px;
  width: 100%;
}
.next_bottom_section {
  margin-top: 70px;
  position: relative;
}
.next_bottom_wrapper {
  background-color: #f1f4f7;
  padding: 8px;
  border: 1px solid #c4cdd5;
  border-top: 0;
  width: 198px;
  border-radius: 0px 0px 5px 5px;
}
.next_bottom_wrapper button {
  width: 100%;
  border: 1px solid #919eac;
  padding: 17px 0;
  margin-bottom: 7px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  background-color: #f2f4f7;
  color: #202223;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}
.next_bottom_wrapper button:last-child {
  margin-bottom: 0;
}
.next_bottom_wrapper button span {
  width: 20px;
  height: 20px;
  margin: 0 3px;
}
.next_bottom_wrapper button svg {
  margin: 0 6px;
}
.next_bottom_wrapper button:focus {
  outline: transparent !important;
}
/* .next_bottom_wrapper button span svg path {
  fill: #5e6dc6;
} */
.up_wrapper .next_bottom_section {
  position: absolute;
  right: -220px;
  top: 0;
}
.upsell_section {
  position: relative;
}
.up_wrapper .offer_section::after {
  content: none;
}
.after_rmv::before {
  content: unset;
}
.spce_left {
  display: flex;
  position: relative;
  top: -30px;
  left: 70px;
}
.main_dynamic {
  position: relative;
  margin-right: 50px;
  width: 200px;
}
.css-26l3qy-menu {
  position: unset !important;
  box-shadow: none !important;
  border: 1px solid #c4cdd5;
  margin-top: 0px !important;
}
.offer_section .Polaris-Icon {
  display: inline-block;
  vertical-align: unset;
  width: 4rem;
  height: 4rem;
}
.accordian_item button {
  border: none;
  border-radius: 3px;
  width: 100%;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.accordian_item {
  border: 1px solid #dedede;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
}
.accordian_item button:focus {
  outline: transparent !important;
}
.last_section h4 {
  display: inline-block;
  color: #202223;
  vertical-align: super;
  margin: 0 5px;
  font-size: 14px;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
}
.accordian_item svg path {
  fill: #676464;
}
.image_block {
  border: 1px solid #cecdd1;
  width: 100%;
  height: 160px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.image_block > img {
  height: 100%;
  margin: auto;
  display: block;
  width: auto;
  max-width: 100%;
  border-radius: 5px;
  object-fit: contain;
}
.image_block > svg {
  width: 100%;
  height: 100%;
}
.image_block.placeholder > svg {
  border-radius: 4px;
}
.accordian_pannel {
  animation: slide-down 0.4s ease;
}
.accrodian_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accrodian_text p {
  color: #8c9196;
  font-size: 14px;
}
.accrodian_text p:nth-child(2) {
  font-weight: 400;
  margin-left: 5px;
}
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.action_tools {
  display: flex;
  align-items: center;
  border: 1px solid #c2cdd6;
  justify-content: space-around;
  background-color: #fff;
  margin-top: 7px;
  border-radius: 5px;
}
.action_tools span {
  width: 33.33%;
  border-right: 1px solid #c2cdd6;
  cursor: pointer;
}
.action_tools span:last-child {
  border-right: 0;
}
.action_wrapper .Polaris-Icon__Svg {
  width: 2.5rem;
  margin: 5px auto;
}
.action_wrapper .Polaris-Icon__Svg:focus {
  outline: transparent !important;
}
.action_wrapper:focus {
  outline: transparent !important;
}
.after_rmv .accordian_item button {
  display: flex;
  align-items: center;
}
.sample_modalSection {
  background: #c4c4c4;
  border: 1px solid #dfe3e8;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  margin: 2rem;
}
.sample_modalSection p {
  padding: 4rem;
  text-align: center;
  color: #212b36;
  font-size: 20px;
  font-weight: 600;
}
.offer_section_type {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.offer_section_type input {
  visibility: hidden;
  display: none;
}
.offer_section_type img {
  width: 300px;
}
.offer_section_type label {
  cursor: pointer;
  margin: 20px;
  position: relative;
}
.offer_section_type h3 {
  padding: 20px 0;
  text-align: center;
  font-size: 20px;
  color: #212b36;
  text-transform: capitalize;
  font-weight: 500;
}
.offer_section_type label:hover::after {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  border: 2px solid grey;
  height: 100%;
  top: 0;
}
.right_side_button {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem 0 0;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
}
.right_side_button p {
  color: #202223;
  font-size: 14px;
  line-height: 19px;
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco", Roboto,
    "Segoe UI", "Helvetica Neue", sans-serif !important;
}
.defaultBadge {
  background-color: #b8e9f9;
  padding: 1px 15px;
  border-radius: 10px;
}
.defaultDraftBadge {
  background-color: #ffea8a;
  padding: 1px 15px;
  border-radius: 10px;
  margin-right: 10px;
}
.right_side_button button:last-child {
  margin-right: 0;
  margin-left: 10px;
  /* font-size: 14px; */
  /* padding: 8px 12px; */
}
.right_side_button button:last-child > span {
  line-height: 20px;
}
.dynamic_append {
  position: relative;
}
.next_bottom_section .down_wrapper::after {
  content: unset;
}
.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge {
  max-width: calc(100% - 6.4rem);
}
.Polaris-Modal-Dialog__Modal {
  max-width: 65%;
}
.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge {
  width: 90%;
  max-width: unset;
}
.Polaris-Modal__BodyWrapper {
  border-radius: 6px;
}
[data-portal-id="modal-Polarisportal-8"] .Polaris-Modal-Dialog__Modal {
  max-width: 40rem;
}
.main_dynamic::after {
  position: absolute;
  content: unset;
  right: -70px;
  border-top: 1px solid #c4cdd5;
  width: 70px;
  top: 40px;
}
.main_dynamic::after:first-child {
  content: "";
}
.upsell_space {
  width: 180px;
  position: relative;
  z-index: 0;
}
#sample_button {
  display: block;
  margin: 10px auto;
}
.accordian_item[data-state="open"] {
  position: relative;
  z-index: 1;
}
.or_section {
  display: flex;
  align-items: center;
  margin: 10px;
}
.or_section p {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
}
.middle_line {
  border-top: 1px solid #dfe3e8;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}
/* custome tooltip */
.tooltip {
  position: relative;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #fff;
  color: rgba(32, 34, 35, 1);
  text-align: left;
  border-radius: 0.4rem;
  padding: 0.4rem 0.8rem;
  position: absolute;
  z-index: 1;
  bottom: 130%;
  left: 30%;
  font-size: 14px;
  margin-left: -20px;
  box-shadow: 1px 0px 20px var(--p-shadow-from-ambient-light), 0px 1px 5px var(--p-shadow-from-direct-light);
}
.tooltip .down_tooltip {
  bottom: 110%;
}
.tooltip_title_bottom {
  width: 100% !important;
  bottom: -36px !important;
  left: 0 !important;
}
.tooltip_title .tooltip_title_bottom::after {
  top: -10px !important;
  transform: rotate(180deg);
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
/* custome tooltip */
.right_down_section {
  position: relative;
}
.up_space:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 75px;
  width: 1px;
  background-color: #c4cdd5;
  margin: auto;
  z-index: -1;
}
.dynamic_append_inner {
  display: flex;
  align-items: flex-start;
}
.react-datepicker__input-container > input {
  padding: 3px 5px 3px 8px;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  min-height: 33px;
  color: hsl(0, 0%, 20%);
}
.react-time-picker__wrapper {
  border: 1px solid hsl(0, 0%, 80%) !important;
  border-radius: 4px;
  min-height: 38px;
  color: hsl(0, 0%, 20%);
}
.react-time-picker__inputGroup__input:focus {
  outline: transparent !important;
}
.react-time-picker__button:focus {
  outline: transparent !important;
}
input[type="time"] {
  border: 1px solid hsl(0, 0%, 80%) !important;
  border-radius: 4px;
  min-height: 38px;
  color: hsl(0, 0%, 20%);
}
.between_time {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.spinner {
  width: 20px;
  height: 20px;
  margin: auto;
  display: block;
  animation: Polaris-Spinner--loading 0.5s linear infinite;
}
@keyframes Polaris-Spinner--loading {
  100% {
    transform: rotate(1turn);
  }
}
.spinner_animate {
  background-color: #f9fafb;
  padding: 5px;
  border: 1px solid #dedede;
}
#deleteTrigger_btn {
  margin-top: 20px;
  padding: 0.7rem;
}
#deleteTrigger_btn svg {
  width: 20px;
}
#deleteTrigger_btn svg path {
  fill: #7d7d7d;
}
.ui-stack {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.ui-stack--wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.ui-stack--alignment-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.ui-stack--distribution-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 1.5rem;
}
.slider-modal-grid-item {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 1px solid #dfe3e8;
  border-radius: 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: black;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 192px;
  width: calc(100% - 20px);
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  cursor: pointer;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 10%), 0px 8px 40px rgb(0 0 0 / 20%);
}
.slider-modal-grid-item:hover {
  color: inherit;
  text-decoration: none;
}
.slider-modal-grid-item:hover .slider-modal-grid-item-image {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.ui-stack > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-top: 1.6rem;
  margin-left: 1.6rem;
}
@media screen and (min-width: 568px) {
  .slider-modal-grid-item {
    width: calc(100% / 2 - 20px);
  }
}
@media screen and (min-width: 768px) {
  .slider-modal-grid-item {
    width: calc(100% / 3 - 20px);
  }
}
.slider-modal-grid-item-image {
  background: center top no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 40px;
  left: 0;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 330ms;
  transition: -webkit-transform 330ms;
  transition: transform 330ms;
  transition: transform 330ms, -webkit-transform 330ms;
  z-index: 50;
  background-size: 100% auto;
}
.slider-modal-grid-item-desc {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-transform: initial;
  letter-spacing: initial;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: white;
  border-top: 1px solid #E4E5E7;
  color: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 4rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 0.4rem 0 1.25rem;
  width: 100%;
  z-index: 100;
}
@media screen and (min-width: 640px) {
  .slider-modal-grid-item-desc {
    font-size: 1.4rem;
  }
}
.tmp_ifrm {
  padding: 0px;
}
.compactview_btn {
  margin: 60px 20px 0;
  display: flex;
  align-items: center;
}
#compactview_btn {
  margin: 18px 0 0 0;
}
/* select 2 */
.select2-container {
  width: 150px !important;
  margin: 0 0 5px 0 !important;
}
.select2-container .select2-selection--single {
  height: 35px !important;
  border: 1px solid #c4cdd5 !important;
  background: linear-gradient(180deg, #fff 0%, #f9fafb 100%);
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 5px 20px 5px 8px !important;
  line-height: 23px !important;
}
.weightSelect .select2-container .select2-selection--single {
  height: 36px !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 33px !important;
}
.dynamic_dropDown .icon-custome {
  position: relative;
  padding-left: 21px !important;
  font-size: 14px !important;
  color: #202223 !important;
}
.dynamic_dropDown .icon-custome::before {
  content: " ";
  vertical-align: middle;
  color: transparent;
  position: absolute;
  top: 13px;
  width: 17px;
  height: 17px;
  margin-right: 6px;
  left: 0px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M0 10c0 5.514 4.486 10 10 10s10-4.486 10-10S15.514 0 10 0 0 4.486 0 10zm5 0a1 1 0 011-1h3V6a1 1 0 112 0v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 01-1-1z' fill='%235C5F62'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.dynamicadd .icon-custome {
  position: relative;
  padding-left: 21px !important;
  font-size: 14px !important;
  color: #202223 !important;
}
.dynamicadd .icon-custome::before {
  content: " ";
  vertical-align: middle;
  color: transparent;
  position: absolute;
  top: 2px;
  width: 17px;
  height: 17px;
  margin-right: 6px;
  left: 0px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M0 10c0 5.514 4.486 10 10 10s10-4.486 10-10S15.514 0 10 0 0 4.486 0 10zm5 0a1 1 0 011-1h3V6a1 1 0 112 0v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 01-1-1z' fill='%235C5F62'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.css-yt9ioa-option {
  color: #000;
  padding: 8px !important;
}
.css-1n7v3ny-option {
  font-size: 14px !important;
  color: #000 !important;
  padding: 8px !important;
  cursor: pointer !important;
}
#accordian_btn {
  background: #fff;
  box-shadow: none;
  border-top: 1px solid #c4cdd5;
  border-right: 1px solid #c4cdd5;
  border-left: 1px solid #c4cdd5;
  border-bottom: 1px solid #c4cdd5;
  padding: 14px 22px;
}
#accordian_btn:hover {
  background: #fff;
}
#accordian_btn[aria-expanded="false"] {
  border: 1px solid #c4cdd5;
  border-radius: 3px;
}
#accordian_btn[aria-expanded="true"] {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  border-bottom: 1px solid transparent;
}
#basic-collapsible[aria-hidden="false"] {
  border-top: none;
  border-bottom: 1px solid #c4cdd5;
  border-left: 1px solid #c4cdd5;
  border-right: 1px solid #c4cdd5;
  background-color: #fff;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 8px 8px 8px;
}
#basic-collapsible:focus {
  outline: transparent !important;
}
#basic-collapsible div:focus {
  outline: transparent !important;
}
.offer_section:focus {
  outline: transparent !important;
}
#accordian_btn span:focus {
  outline: transparent !important;
}
.last_section h4:focus {
  outline: transparent !important;
}
.Polaris-Button svg:focus {
  outline: transparent !important;
}
@media screen and (max-width: 767px) {
  .block_section > div {
    width: 100%;
    margin-right: 0px;
  }
  .select2-container {
    width: 100% !important;
  }
}
#txt_value {
  font-size: 14px;
  line-height: 2.4rem;
  text-transform: none;
  letter-spacing: normal;
  position: relative;
  z-index: 20;
  display: block;
  flex: 1 1;
  width: 100%;
  min-width: 0;
  min-height: 2.6rem;
  margin: 0;
  padding: 4px 20px 4px 8px;
  line-height: 23px;
  background: none;
  border: 1px solid #bdc6cd;
  color: #444;
  border-radius: 4px;
  background-image: linear-gradient(
    to bottom,
    #fff,
    #fefefe,
    #fcfdfd,
    #fafbfc,
    #f9fafb
  );
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
    Roboto, Helvetica Neue, sans-serif !important;
}
#txt_value:focus {
  outline: transparent !important;
}
#txt_valueFrom {
  font-size: 14px;
  line-height: 2.4rem;
  text-transform: none;
  letter-spacing: normal;
  position: relative;
  z-index: 20;
  display: block;
  flex: 1 1;
  min-width: 0;
  min-height: 3.6rem;
  padding: 5px 20px 5px 8px;
  line-height: 23px;
  background: none;
  border: 1px solid #bdc6cd;
  color: #444;
  border-radius: 4px;
  background-image: linear-gradient(
    to bottom,
    #fff,
    #fefefe,
    #fcfdfd,
    #fafbfc,
    #f9fafb
  );
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
    Roboto, Helvetica Neue, sans-serif !important;
}
#txt_valueFrom:focus {
  outline: transparent !important;
}
#txt_valueTo {
  font-size: 14px;
  line-height: 2.4rem;
  text-transform: none;
  letter-spacing: normal;
  position: relative;
  z-index: 20;
  display: block;
  flex: 1 1;
  min-width: 0;
  min-height: 3.6rem;
  padding: 5px 20px 5px 8px;
  line-height: 23px;
  background: none;
  border: 1px solid #bdc6cd;
  color: #444;
  border-radius: 4px;
  background-image: linear-gradient(
    to bottom,
    #fff,
    #fefefe,
    #fcfdfd,
    #fafbfc,
    #f9fafb
  );
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
    Roboto, Helvetica Neue, sans-serif !important;
}
#txt_valueTo:focus {
  outline: transparent !important;
}
.custome_date {
  display: flex;
  align-items: baseline;
  display: block ruby;
}
#fromtime {
  padding: 5px 5px 5px 8px;
  line-height: 23px;
  background: none;
  border: 1px solid #bdc6cd;
  color: #444;
  border-radius: 4px;
  background-image: linear-gradient(
    to bottom,
    #fff,
    #fefefe,
    #fcfdfd,
    #fafbfc,
    #f9fafb
  );
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
    Roboto, Helvetica Neue, sans-serif !important;
  font-size: 14px;
}
#totime {
  padding: 5px 5px 5px 8px;
  line-height: 23px;
  background: none;
  border: 1px solid #bdc6cd;
  color: #444;
  border-radius: 4px;
  background-image: linear-gradient(
    to bottom,
    #fff,
    #fefefe,
    #fcfdfd,
    #fafbfc,
    #f9fafb
  );
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
    Roboto, Helvetica Neue, sans-serif !important;
  font-size: 14px;
}
#time {
  padding: 5px 5px 5px 8px;
  line-height: 23px;
  background: none;
  border: 1px solid #bdc6cd;
  color: #444;
  border-radius: 4px;
  background-image: linear-gradient(
    to bottom,
    #fff,
    #fefefe,
    #fcfdfd,
    #fafbfc,
    #f9fafb
  );
  font-size: 14px;
  font-family: -apple-system, blinkmacsystemfont, san francisco, segoe ui,
    roboto, helvetica neue, sans-serif !important;
}
#time:focus {
  outline: transparent !important;
}
.react_datePicker {
  margin-right: 10px;
  padding: 5px 5px 5px 8px;
  line-height: 23px;
  background: none;
  border: 1px solid #bdc6cd;
  color: #444;
  border-radius: 4px;
  background-image: linear-gradient(
    to bottom,
    #fff,
    #fefefe,
    #fcfdfd,
    #fafbfc,
    #f9fafb
  );
}
.react_datePicker:focus {
  outline: transparent !important;
}
.Polaris-Modal__Body .Polaris-Modal-Section {
  padding: 0;
}
.Polaris-Modal__Body {
  line-height: 1px;
}
.Polaris-Modal-Section > iframe {
  border: 0;
}
.delete_txt_wrapper {
  padding: 30px 20px;
  line-height: 20px;
}
.delete_txt_wrapper > p {
  font-size: 15px;
}
#startDate {
  font-size: 14px;
  padding: 5px 5px 5px 8px;
  min-height: 33px;
  line-height: 23px;
  background: none;
  border: 1px solid #bdc6cd;
  color: #444;
  border-radius: 4px;
  background-image: linear-gradient(
    to bottom,
    #fff,
    #fefefe,
    #fcfdfd,
    #fafbfc,
    #f9fafb
  );
  font-family: -apple-system, blinkmacsystemfont, san francisco, segoe ui,
    roboto, helvetica neue, sans-serif !important;
}
#endDate {
  font-size: 14px;
  padding: 5px 5px 5px 8px;
  min-height: 33px;
  line-height: 23px;
  background: none;
  border: 1px solid #bdc6cd;
  color: #444;
  border-radius: 4px;
  background-image: linear-gradient(
    to bottom,
    #fff,
    #fefefe,
    #fcfdfd,
    #fafbfc,
    #f9fafb
  );
  font-family: -apple-system, blinkmacsystemfont, san francisco, segoe ui,
    roboto, helvetica neue, sans-serif !important;
}
.lable {
  padding: 0;
  position: relative;
}
.snehainput {
  outline: none;
  padding: 5px 20px 5px 35px !important;
}
.prefix {
  position: absolute;
  z-index: 111;
  top: 24%;
  left: 7px;
  color: grey;
  font-size: 13px;
}
.suffix {
  position: absolute;
  z-index: 111;
  top: 23%;
  right: 11px;
  color: grey;
}
.suffix_input {
  padding: 5px 25px 5px 5px !important;
}
.order-value {
  padding: 5px 20px 5px 30px !important;
}
.ReactTags__selected {
  padding: 5px 5px 5px 5px;
  min-height: 33px;
  line-height: 23px;
  background: none;
  border: 1px solid #bdc6cd;
  color: #444;
  border-radius: 4px;
  background-image: linear-gradient(
    to bottom,
    #fff,
    #fefefe,
    #fcfdfd,
    #fafbfc,
    #f9fafb
  );
}
div.ReactTags__tags {
  position: relative;
  width: 200px;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  outline: transparent;
  border: unset;
  background-color: transparent;
  color: #444;
  width: 100%;
}
.ReactTags__tagInput {
  display: inline-block;
  color: #444;
}
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  padding: 0px 5px;
  margin: 0 5px 0 0;
  border-radius: 2px;
  cursor: pointer !important;
  word-break: break-all;
}
div.ReactTags__selected a.ReactTags__remove {
  cursor: pointer;
  padding: 0;
  font-size: 15px;
  font-weight: 900;
  border: 0;
  background-color: transparent;
}
div.ReactTags__suggestions {
  position: absolute;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}
.button_div {
  display: flex;
  align-items: center;
}
.next_right_section:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 35px;
  width: 1px;
  background-color: #c4cdd5;
  margin: auto;
  z-index: -1;
}
.last_section > .up_space {
  margin-top: 0;
}
.next_right_section > .up_space {
  margin-top: 0;
}
.up_space {
  margin-top: 70px;
}
.rmv_after_before::after {
  content: unset;
}
.rmv_after_before::before {
  content: unset;
}
.remove_margin_bottom {
  margin-bottom: -90px;
}
.first_downsell {
  margin-top: 70px;
}
.css-4ljt47-MenuList::-webkit-scrollbar {
  width: 10px;
}
.css-4ljt47-MenuList::-webkit-scrollbar-track {
  background: #fafafa;
  border: 1px solid #c4cdd5;
  border-radius: 8px;
}
.css-4ljt47-MenuList::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 8px;
}
.funnel_app h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.funnel_app h3 > .collpase_right {
  position: absolute;
  right: 10px;
  transform: rotate(180deg);
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
}
.funnel_app h3 > .collpase_right:focus {
  outline: transparent;
}
.funnel_app h3 > .collpase_right svg path,
.funnel_app h3 > .expand_right svg path {
  fill: #5c5f62;
}
.funnel_app h3 > .expand_right {
  position: absolute;
  right: 10px;
  transform: none;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
}
.funnel_app h3 > .expand_right:focus {
  outline: transparent;
}
.funnel_app.expand_right {
  animation: collapse_right 0.8s ease;
  will-change: transform;
  width: 198px;
  height: 107px;
  top: 31px;
}
.add_overflow {
  display: none;
}
@keyframes collapse_right {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.funnel_app.collpase_right {
  animation: collapse_left 0.8s ease;
  will-change: transform;
}
@keyframes collapse_left {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.Polaris-Modal-CloseButton--withoutTitle {
  right: -20px;
  top: -30px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
}
.Polaris-Modal-CloseButton--withoutTitle:active {
  background-color: #fff;
  border-radius: 50%;
}
.Polaris-Modal-CloseButton--withoutTitle:focus {
  background-color: #fff;
  border-radius: 50%;
}
.header_devide_block {
  display: flex;
  align-items: center;
}
/* .header_devide_block > button {
  font-size: 14px;
  padding: 8px 12px;
} */
.header_devide_block > button > span {
  line-height: 20px;
}
.back_icon_arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 500;
  padding: 16px 2rem;
  border-right: 1px solid #dfe3e8;
}
.back_icon_arrow span {
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: #637481;
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco", Roboto,
    "Segoe UI", "Helvetica Neue", sans-serif !important;
}
.back_icon_arrow span.Polaris-Icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.funnel_name {
  color: #202223;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco", Roboto,
    "Segoe UI", "Helvetica Neue", sans-serif !important;
  padding: 17.5px 2rem;
  border-right: 1px solid #dfe3e8;
  width: 195px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.back_icon_arrow svg > path {
  fill: #637481;
}
.custome_iframeLoader {
  position: fixed;
  z-index: 33333;
  width: 100%;
  height: 80%;
  top: 10%;
}
.custome_iframeLoader .css-120ugjf {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 11111;
  width: 50px;
  height: 50px;
}
.and_section {
  margin-top: 2px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  margin-left: -7px !important;
}
.select2-container .select2-selection--multiple {
  min-height: 33px !important;
}
.select2-container--default .select2-selection--multiple {
  background-image: linear-gradient(
    to bottom,
    #fff,
    #fefefe,
    #fcfdfd,
    #fafbfc,
    #f9fafb
  );
  border: 1px solid #bdc6cd !important;
  color: #444;
}
/* premade template */
.free-themes-modal-details {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  animation: slideTo_right 0.5s ease;
  overflow: hidden;
}
@keyframes slideTo_right {
  0% {
    opacity: 0;
    transform: translateX(900px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@media screen and (min-width: 768px) {
  .free-themes-modal-details {
    flex-direction: row;
  }
}
.free-themes-modal-images {
  background-color: #dfe3e8;
  max-height: 280px;
  overflow: hidden;
  margin: -20px -20px 20px;
  padding: 20px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .free-themes-modal-images {
    border: 0;
    flex-basis: 60%;
    margin: -20px 0 -20px -20px;
    max-height: calc(680px - 188px);
    padding: 20px 30px 20px 20px;
  }
}
@media screen and (min-width: 768px) {
  .free-themes-modal-mobile-screen-wrapper {
    display: block;
    position: absolute;
    bottom: -20%;
    right: 20px;
    max-width: 38%;
  }
}
.free-themes-modal-mobile-device {
  height: auto;
  width: 100%;
}
.free-themes-modal-mobile-image {
  bottom: 42px;
  height: auto;
  padding: 0 12px;
  position: absolute;
  right: 0;
  width: 100%;
}
.free-themes-modal-image {
  border: 1px solid #e5e5e5;
  display: block;
  max-width: 100%;
}
.free-themes-modal-details-info {
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .free-themes-modal-details-info {
    flex-basis: 40%;
  }
}
.free-themes-modal-details-info > *:not(.hide) + * {
  margin-top: 1.6rem;
}
.free-themes-modal-details-info ul {
  list-style: disc inside none;
}
.free-themes-modal-details-info ul li {
  font-size: 15px;
  line-height: 20px;
}
.free-themes-modal-details-info p {
  font-size: 15px;
  line-height: 20px;
}
[data-portal-id="modal-Polarisportal-3"]
  .Polaris-Modal__Body
  .Polaris-Modal-Section {
  max-height: 533px;
  height: 533px;
  overflow-x: hidden;
  overflow-y: auto;
}
#Polarismodal-header3 .Polaris-Icon {
  margin: 0 10px 0 0;
  display: inline-block;
  vertical-align: text-bottom;
}
#Polarismodal-header3 .Polaris-Icon svg > path {
  fill: #637381;
}
#Polarismodal-header3 h2 {
  color:inherit;
}
#Polarismodal-header3 button {
  cursor: pointer;
  background-color: transparent;
  border: 0;
}
#Polarismodal-header3 button:focus {
  outline: transparent;
}
[data-portal-id="modal-Polarisportal-10"] .Polaris-Modal-Dialog__Modal{
  position: relative;
    max-width: 62rem ;
    margin: 0 auto;
    border-radius: var(--p-border-radius-wide);
}
[data-portal-id="modal-Polarisportal-video"] .Polaris-Modal-Dialog__Modal {
  text-align: center;
  max-width: 710px;
}
.sliderTo_left {
  animation: slideTo_left 0.8s ease;
}
@keyframes slideTo_left {
  0% {
    opacity: 0;
    transform: translateX(0px);
  }
  100% {
    opacity: 1;
    transform: translateX(900px);
  }
}
.and_section p {
  font-size: 14px;
}
.slider-modal-grid-item-image > img {
  width: 100%;
  height: 100%;
}
.editFunnelButton {
  background-color: #f2f4f7;
  border: 1px solid #919eac;
  margin: 8px;
  border-radius: 3px;
  padding: 19px 0;
  color: #000;
  font-size: 14px;
  cursor: pointer;
  height: 58px;
}
.editFunnelButton > button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: transparent;
  border: 0;
  color: #202223;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.editFunnelButton > button:focus {
  outline: transparent;
}
.editFunnelButton > button > span {
  margin: 0 10px 0 0;
}
.span_txt_indicator {
  position: relative;
  right: 0;
  top: -14px;
  margin: 0 48px;
  color: #8c9196;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 2rem;
  font-size: 14px;
  line-height: 1.6rem;
  z-index: 1;
  text-align: center;
  width: max-content;
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco", Roboto,
    "Segoe UI", "Helvetica Neue", sans-serif !important;
}
.span_txt_indicator::before {
  content: "";
  position: absolute;
  left: -50px;
  border-top: 1px solid #c4cdd5;
  width: 50px;
  top: 13px;
}
.span_txt_indicator::after {
  content: "";
  position: absolute;
  right: -50px;
  border-top: 1px solid #c4cdd5;
  width: 50px;
  top: 13px;
}
.next_right_section {
  width: 198px;
  position: relative;
  right: 0px;
  top: -32px;
}
.downsell_indicator::after {
  content: unset;
}
.downsell_indicator::before {
  content: unset;
}
.downsell_indicator {
  margin: auto;
  top: -55px;
}
.downsell_indicator_wrapper {
  position: absolute;
  bottom: -57px;
  left: 0;
  right: 0;
  margin: auto;
}
.downsell_indicator_wrapper .downsell_indicator {
  top: 8px;
}
.open_indicator_downsell {
  margin: 16px auto;
}
.green_badge {
  background-color: #bbe5b3;
  border: 0.2rem solid #ffffff;
  padding: 5px 10px;
  top: -16px;
  position: relative;
}
.green_badge::before {
  content: "";
  position: absolute;
  left: -52px;
  border-top: 1px solid #c4cdd5;
  width: 52px;
  top: 13px;
}
.green_badge::after {
  content: "";
  position: absolute;
  right: -52px;
  border-top: 1px solid #c4cdd5;
  width: 52px;
  top: 13px;
}
.orange_badge {
  background-color: #ffc58b;
  border: 0.2rem solid #ffffff;
  padding: 5px 10px;
  padding: 5px 10px;
}
.blue_badge {
  background-color: #b4e1fa;
  border: 0.2rem solid #ffffff;
  padding: 5px 10px;
  padding: 5px 10px;
  position: relative;
  top: -14px;
}
.blue_badge::before {
  top: 11px;
}
.blue_badge::after {
  top: 11px;
}
.span_txt_indicator.downsell_indicator.blue_badge {
  top: -15px;
}
.back_icon_arrow {
  text-decoration: none;
}
/* custome tooltip */
#accordian_btn .Polaris-Button__Text > .tooltip_title {
  display: flex;
  align-items: center;
}
.tooltip_title {
  position: relative;
  cursor: pointer;
}
.tooltip_title .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #fff;
  color: rgba(32, 34, 35, 1);
  text-align: left;
  border-radius: 0.4rem;
  padding: 0.4rem 0.8rem;
  position: absolute;
  z-index: 1;
  bottom: 130%;
  left: 30%;
  font-size: 14px;
  margin-left: -20px;
  box-shadow: 1px 0px 20px var(--p-shadow-from-ambient-light), 0px 1px 5px var(--p-shadow-from-direct-light);
}
.tooltip_title .tooltiptextdiscount {
  visibility: hidden;
  width: 24em;
  background-color: #fff;
  color: rgba(32, 34, 35, 1);
  text-align: left;
  border-radius: 0.4rem;
  padding: 0.4rem 0.8rem;
  position: absolute;
  z-index: 1;
  bottom: 130%;
  right: 0px;
  font-size: 14px;
  margin-left: auto;
  box-shadow: 1px 0px 20px var(--p-shadow-from-ambient-light), 0px 1px 5px var(--p-shadow-from-direct-light);
}
.tooltip_title .down_tooltip {
  bottom: 91%;
  left: 20px;
  padding: 0.4rem 0.8rem;
}
.tooltip_title:hover .tooltiptext {
  visibility: visible;
}
.tooltip_title:hover .tooltiptextdiscount {
  visibility: visible;
}
/* custome tooltip */
.selct_ofr_type {
  width: 300px;
  background-color: #fff;
  height: 250px;
}
.selct_ofr_type > svg {
  width: 100%;
  padding: 40px 20px;
  height: 100%;
}
.andBtn_bg {
  display: block;
  background-color: #fff;
}
.andBtn_bg {
  padding-left: 10px;
  border-top: 0;
  padding-bottom: 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-top: 5px;
}
.topBoder {
  border: 1px solid #c4cdd5;
}
.dynamic_append > .tooltip_title > .down_tooltip {
  text-transform: uppercase;
  font-size: 14px;
}
.no_badge_here::before {
  content: "";
  position: absolute;
  left: -50px;
  border-top: 1px solid #c4cdd5;
  width: 50px;
  top: -4px;
}
.no_badge_here::after {
  content: "";
  position: absolute;
  right: -50px;
  border-top: 1px solid #c4cdd5;
  width: 50px;
  top: -4px;
}
.custome_iframeLoader .css-cfemge {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 11111;
}
.middle_section > :nth-child(1).blue_badge::before {
  content: "";
  position: absolute;
  left: -52px;
  border-top: 1px solid #c4cdd5;
  width: 52px;
  top: 14px;
}
.middle_section > :nth-child(1).blue_badge::after {
  content: "";
  position: absolute;
  right: -52px;
  border-top: 1px solid #c4cdd5;
  width: 52px;
  top: 14px;
}
.thankyou_action span {
  width: 50%;
}
.tooltip .trigger_tooltip {
  width: auto;
  padding: 0.4rem 0.8rem;
  bottom: auto;
  z-index: 111;
  top: 50px;
}
.trigger_tooltip p {
  background-color: #fff;
  width: max-content;
  color: rgba(32, 34, 35, 1);
  text-align: center;
  display: block;
}
.tooltip .trigger_tooltip::after {
  left: 20px;
  top: -9px;
  transform: rotate(180deg);
}
/* recommadation slider */
.recomm_slider {
  width: 147px;
  display: block;
  height: 158px;
}
.recomm_slider .slick-prev:before,
.recomm_slider .slick-next:before {
  color: #000;
}
.recomm_slider .slick-prev {
  z-index: 1111;
  left: -5px;
  top: 25px;
}
.recomm_slider .slick-prev::before {
  content: "<";
  font-family: cursive;
  color: rgb(100, 116, 129);
  opacity: 1;
}
.recomm_slider .slick-next {
  z-index: 1111;
  right: -5px;
  top: 25px;
}
.recomm_slider .slick-next::before {
  content: ">";
  font-family: cursive;
  color: rgb(100, 116, 129);
  opacity: 1;
}
.recomm_slider .slick-slide > div > svg:focus {
  outline: none;
  cursor: pointer;
}
.recomm_slider .slick-slide img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 5px;
  object-fit: contain;
}
.recomm_slider .slick-list {
  padding: 0 10px;
  margin: 50px 0;
}
.recomm_slider .slick-slide p {
  font-size: 12px;
  font-weight: 600;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.slick-slide > div > div {
  width: 50px !important;
  height: 50px;
}
.image_block.placeholder .slick-list {
  padding: 0;
  margin: 0;
}
.image_block.placeholder .slick-next {
  right: 0px;
  top: 47%;
}
.image_block.placeholder .slick-prev {
  left: 0px;
  top: 47%;
}

/* fallback template */
.fallback_template {
  width: 450px;
  top: 0;
}
.is_bottom {
  margin: 30px 0 20px 20px;
}
.is_last {
  margin-top: 50px;
}
.fallback_template .trigeer_heading {
  display: flex;
  align-items: center;
}
.fallback_template .trigeer_heading .Polaris-Icon svg {
  margin-top: 0;
}
.fallback_template .trigeer_heading .Polaris-Icon {
  position: absolute;
  right: 0px;
}
.fallback_template h3 {
  padding: 5px 0;
  border: 0;
  background-color: #fff;
  width: 100%;
}
.Polaris-Frame__Main {
  position: relative;
}
.fallback_desc {
  padding: 10px 5px;
  background-color: #fff;
}
.fallback_desc > p {
  color: #6d7175;
  font-size: 14px;
}
.fallback_desc > p > a {
  text-decoration: none;
  color: #0077cc;
}
.css-2b097c-container {
  width: 198px;
}
.select_top {
  color: #202223;
  margin-bottom: 5px;
}
.css-1uccc91-singleValue {
  color: #555555 !important;
}
.fallback_template #accordian_btn {
  width: 100%;
  border-bottom: 1px solid #c4cdd5;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  background-color: #fff;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  display: block;
  padding: 0px 10px 0px 10px;
  min-height: 31px;
}
.fallback_template #basic-collapsible {
  border: 0;
  background-color: #fff;
}
.fallback_template #basic-collapsible[aria-hidden="false"] {
  overflow: unset;
}
.fallback_template #accordian_btn[aria-expanded="false"] {
  border: 0;
  border-radius: 5px;
}
/* .fallback_template .Polaris-Icon__Svg path {
  fill: #909fab;
} */
.Polaris-Frame__Content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.fallback_acc {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fallback_acc .thanku_size {
  font-weight: 400 !important;
  margin-left: 5px;
}
.fallback_acc .accrodian_text {
  justify-content: unset;
}
/* funnel title input */
#funnelTitle {
  padding : 6px 12px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #d3dbe2;
  color: #202223;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
    Roboto, Helvetica Neue, sans-serif !important;
  width: 193px;
}
.titleInput {
  padding: 0px 2rem;
  border-right: 1px solid #dfe3e8;
}
#funnelTitle:focus {
  outline-color: #458fff;
}
.image_dropdown {
  display: flex;
  align-items: center;
}
.dropdown_image {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
#space_txt .css-tlfecz-indicatorContainer,
#space_txt .css-1gtu0rj-indicatorContainer,
#space_txt .css-1hb7zxy-IndicatorsContainer,
#space_txt .css-1okebmr-indicatorSeparator,
.rmv_select_icon .css-tlfecz-indicatorContainer,
.rmv_select_icon .css-1gtu0rj-indicatorContainer,
.rmv_select_icon .css-1hb7zxy-IndicatorsContainer,
.rmv_select_icon .css-1okebmr-indicatorSeparator {
  display: none !important;
}
#space_txt .css-26l3qy-menu,
.rmv_select_icon .css-26l3qy-menu {
  position: absolute !important;
  z-index: 1111;
}
#space_txt .css-xb97g8:hover,
.rmv_select_icon .css-xb97g8:hover {
  background-color: unset;
  color: #333333;
}
.block_section #space_txt .css-2b097c-container {
  width: 220px;
}
.error_banner {
  background-color: #fbeae5;
  box-shadow: inset 0 3px 0 0 #de3618, inset 0 0 0 0 transparent,
    0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
  position: relative;
  display: flex;
  padding: 1.6rem;
  border-radius: 0 0 3px 3px;
}
.error_svg {
  flex: 0 0 3.2rem;
  margin-right: 1.6rem;
}
.error_svg > span {
  cursor: pointer;
  fill: #bf0711;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0.8rem;
}
.Polaris-Banner__Heading {
  /* margin-top: 0.4rem; */
  padding: 0;
}
.Polaris-Banner__Ribbon{
  margin-top: 4px;
}
.Polaris-Banner__Content {
  margin: 0.8rem 0;
  word-break: unset;
  overflow-wrap: unset;
  padding: 0;
}
.Polaris-Banner__Content > ul {
  margin: 0;
  padding: 0;
}
.error_message {
  margin-left: 20px;
  width: 600px;
  margin-top: 20px;
}
.sweet-loading.page_loader {
  position: fixed;
  top: 52px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(51 51 51 /0.1);
}
.sweet-loading.page_loader > div {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.time_picker_wrapper {
  height: 500px;
  width: 300px;
  margin: 50px auto;
}
.time_picker_wrapper2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.time_picker_trigger {
  width: auto;
  display: inline-block;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.gap {
  float: left;
  width: 20px;
}
.time_picker_container {
  width: 300px;
}
#txt_value.weightPicker {
  padding: 5px 57px 5px 8px;
}
#space_txt.weightSelect {
  z-index: 111;
  margin-left: -60px;
}
.weightSelect .select2-container {
  width: 60px !important;
  margin: 0 !important;
}
.weightSelect .select2-container .select2-selection--single {
  border-left: 1px solid #aaa !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.weightSelect.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 31px !important;
}
@media screen and (max-width: 780px) {
  #space_txt.weightSelect {
    margin-left: 0px;
    margin-top: 10px;
  }
  #txt_value.weightPicker {
    padding: 5px 20px 5px 8px;
  }
  .weightSelect .select2-container {
    width: 100% !important;
  }
  #space_txt {
    margin-top: 10px;
  }
}
#space_txt .css-yk16xz-control {
  border: 1px solid #c4cdd5 !important;
  background: linear-gradient(180deg, #fff 0%, #f9fafb 100%);
}
/* time picker */
.custom-timepicker-bubble .timepicker-bubble circle {
  fill: transparent;
}
.custom-timepicker-bubble .timepicker-bubble text {
  fill: #666;
  font-size: 15px;
}
.custom-timepicker-bubble .timepicker-bubble:hover circle,
.timepicker-bubble.active circle {
  fill: rgba(0, 149, 221, 0.25);
}
.timepicker {
  background-color: #f8f8f8;
  width: fit-content;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
.timepicker > svg {
  border-radius: 50%;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  margin: 12px;
}
.custom-style {
  padding: 10px 0px 0px;
  position: absolute;
}
.timepicker > p {
  padding: 8px 14px;
  font-size: 24px;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
  margin-right: 0;
  width: auto !important;
}
.discountValue {
  display: flex;
  align-items: center;
}
.timepickerdiv {
  width: 148px;
  text-align: left;
}
.helpVidio_btn svg {
  margin-left: 10px;
}
.helpVidio_btn {
  /* padding: 8px 12px;
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: rgb(22 29 37 / 5%) 0 1px 0 0;
  background-clip: padding-box;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #202223;
  border: 1px solid #8c9196;
  border-radius: 4px;
  background: linear-gradient(#fff, #f9fafb);
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
  margin-left: 20px !important;
  /* position: relative; */
}
.Polaris-btn-Button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 3.6rem;
  min-width: 3.6rem;
  margin: 0;
  padding: 0.7rem 1.6rem;
  box-shadow: var(--p-button-drop-shadow);
  border-radius: var(--p-border-radius-base);
  color: #202223;
  border: 1px solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  line-height: 1;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
.play-custom-polari-mrg-r {
  margin-left: 10px;
}
.editor-icon {
  margin: auto;
  top: 3px;
  width: 19px;
  height: 15px;
  position: relative;
  color: transparent;
  fill: #454f5b;
  line-height: 20px;
  margin-right: 10px;
}
.fallback_desc .create_new_temp::before {
  content: " ";
  vertical-align: middle;
  color: transparent;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 14px;
  margin-right: 6px;
  left: 7px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M0 10c0 5.514 4.486 10 10 10s10-4.486 10-10S15.514 0 10 0 0 4.486 0 10zm5 0a1 1 0 011-1h3V6a1 1 0 112 0v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 01-1-1z' fill='%235C5F62'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.fallback_desc .create_new_temp {
  position: relative;
  padding-left: 30px !important;
  font-size: 14px !important;
  color: #202223 !important;
}
.fallback_desc
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #deebff;
  color: #000;
}
.fallback_desc .select2-container {
  width: 170px !important;
}
.is_single {
  margin-bottom: 50px;
}
#space_txt .ReactTags__tagInput {
  display: block;
}
.next_right_section.rmv_after_before .sweet-loading {
  position: absolute;
  top: 15px;
}
.leftdiv {
  margin-right: 5px !important;
}
.rightdiv {
  margin-left: 5px !important;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  white-space: normal;
  border: 1px solid #ddd !important;
  background-color: #eee !important;
  padding: 0px 5px !important;
  margin: 5px 5px 0 5px;
  border-radius: 2px !important;
  cursor: pointer !important;
  word-break: break-all;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #555555 !important;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 0 !important;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #444 !important;
  font-size: 15px;
  font-weight: 900 !important;
  margin-left: 10px !important;
  margin-right: 0 !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  font-size: 1.4rem;
}
.css-g1d714-ValueContainer,
.css-1hwfws3 {
  padding: 5px !important;
}
.css-6q0nyr-Svg {
  fill: #444;
  cursor: pointer;
}
.css-19bqh2r {
  fill: #444;
  cursor: pointer;
}
.image_dropdown > span {
  font-size: 14px;
  white-space: normal;
}
.css-1rhbuit-multiValue {
  border: 1px solid #ddd;
  background-color: #eee !important;
  margin: 0 5px 5px 0 !important;
}
.css-12jo7m5 {
  padding-left: 3px !important;
}
#deleteTrigger_btn svg {
  margin-top: 0;
}
.react-datepicker-popper {
  z-index: 111 !important;
}
.weightSelect
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 25px !important;
}
.weight_to {
  margin-top: 26px !important;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .weight_to {
    width: 100%;
    margin: 5px 0 0 0 !important;
  }
}
[data-portal-id="modal-Polarisportal-9"] .Polaris-Modal-Header {
  text-align: center;
}
[data-portal-id="modal-Polarisportal-9"] .Polaris-TextContainer {
  font-size: 20px;
  text-align: center;
  line-height: 2.5rem;
  padding: 20px;
}
[data-portal-id="modal-Polarisportal-9"] .Polaris-Modal-Footer__FooterContent {
  display: flex;
  justify-content: center;
}
[data-portal-id="modal-Polarisportal-9"] .Polaris-Button__Text {
  font-weight: normal;
  font-size: 15px;
}
[data-portal-id="modal-Polarisportal-9"] .Polaris-Modal-Dialog__Modal {
  max-width: 710px;
}
/* outline drop down */
.select2-container--default .select2-results > .select2-results__options {
  outline-color: #c4cdd5 !important;
  outline: 1px solid #c4cdd5 !important;
}
.toggleButton {
  margin-left: 10px;
  height: 18px;
}
.app_disable {
  pointer-events: none;
  opacity: 0.5;
}
/* fallback dropdown top side */
.drop-down-select2 .select2-dropdown {
  bottom: 100%;
  margin-bottom: 33px;
}
.drop-down-select2
  .select2-container--default
  .select2-results
  > .select2-results__options {
  max-height: 163px;
}
.drop-down-select2 .select2-search--dropdown {
  border-top: 1px solid #c4cdd5;
}
/* focus css */
#startDate:focus,
#endDate:focus,
#fromtime:focus,
#totime:focus {
  outline: transparent !important;
}
/* datepicker  */
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1.3rem !important;
  font-weight: normal !important;
}
.react-datepicker__header {
  padding-top: 2px !important;
}
.react-datepicker__navigation {
  top: 7px !important;
}
.react-datepicker {
  font-size: 1.3rem !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0rem !important;

  padding: 2px !important;
  width: 26px !important;
}
.react-datepicker__month {
  margin: 0.2rem !important;
}
.divDisable {
  pointer-events: none;
}
.disabled_switch {
  opacity: 0.5;
  pointer-events: none;
}
.text_overflow{
  display: -webkit-box  !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.tooltip_text_overflow{
  display: -webkit-box !important;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    max-height: 138px;
    overflow: hidden;
    padding: 8px 3px 10px !important;
    word-break: break-word;
}
[data-portal-id="modal-Polarisportal-3"] .Polaris-Modal-Dialog__Modal {
  max-width: 1000px;
  width: 862px;
  max-height: calc(100vh - 294px);
 }
 [data-portal-id="modal-Polarisportal-3"] .Polaris-Modal__Body .Polaris-Modal-Section {
   height: 100%;
   padding-bottom: 16px;
 }
 [data-portal-id="modal-Polarisportal-3"] .Polaris-TextField {
   width : 100px;
 }
 .margin_left_eight{
   margin-left: 8px;
 }
 [data-portal-id="modal-Polarisportal-3"] .Polaris-Stack__Item {
   margin-left: 8px;
 }
.justify_content_flex_end{
  justify-content: flex-end;
}
[data-portal-id="modal-Polarisportal-3"] .Polaris-TextField__Spinner{
  display: none;
}
[data-portal-id="modal-Polarisportal-3"] .Polaris-Modal__BodyWrapper{
  min-height: 330px;
  height: auto;
  background-color: #F4F6F8;
  }
[data-portal-id="modal-Polarisportal-3"] .ui-stack--distribution-center{
  padding: 0px 6px;
}
[data-portal-id="modal-Polarisportal-3"] .free-themes-modal-details{
  margin-bottom: -1.6rem;
}
.empty_template_img {
    height: 64px;
    width: 64px;
    margin: auto;
}
.add_spaceMargin{
  height: 107px;
}
[data-portal-id="modal-Polarisportal-11"] .Polaris-Modal-Dialog__Modal{
  max-height: calc(100vh - 60px);
  max-width: 62rem;
}
[data-portal-id="modal-Polarisportal-11"] .Polaris-Modal-Dialog__Container{
  background-color: rgba(0, 0, 0, 0.5);
}
#PolarisBanner2Content{
  color:red;
}
.status-poupup{
  margin: 84px 19px 0;
    display: flex;
    align-items: center;
}
.status-poupup8{
  margin: 84px 19px 0;
  display: flex;
  align-items: center;
}
.status-poupup8 .Polaris-Banner{
  width: 100%;
}
.rmv-compactview_btn{
  margin:20px 20px 0;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
  background-color: rgba(0, 128, 96, 1) !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
  background-color: rgba(0, 128, 96, 1) !important;
}

/* input[type="time"]::-webkit-calendar-picker-indicator{
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
} */

/* input[type="time"]::-webkit-inner-spin-button{
  filter: rgba(0, 128, 96, 1);
} */
/* input::-webkit-datetime-edit-hour-field:focus,input::-webkit-datetime-edit-minute-field:focus,input::-webkit-datetime-edit-ampm-field:focus {
  background-color:rgba(0, 128, 96, 1);
} */

